<template>
  <div>
    <a-row class=" pbz-font d-flex align-items-center">
      <a-col :span="1" class="d-flex justify-content-start">
        <div @click.prevent="back()" style="cursor: pointer">
          <ArrowIcon class="ant-dropdown-link" @click="e => e.preventDefault()"
            style="color: #0559cb; height: 100px; width:25px;"
            :rotate="'rotate(270)'"
          />
        </div>
      </a-col>
      <a-col class="d-flex justify-content-center align-items-center" :span="5" style="border-left: 1px solid rgb(204, 204, 204);">
        <div style="color: #0559CB; margin-right: 5px;">Daftar Order ></div> Export History
      </a-col>
    </a-row>
    <a-row class="d-flex" style="margin-bottom: 20px">
      <a-col :span="8" >
        <p class="pbz-font title">Daftar History</p>
      </a-col>
      <a-col :span="8" :offset="8" class="d-flex justify-content-end" @click="handleFilter()">
        <div class="mx-2 filter-box">
          <div>
            <FilterIcon style="color: #ffffff" />
          </div>
          <div class="caption">Filter</div>
        </div>
      </a-col>

      <!-- MODAL FILTER -->
      <a-modal :visible="filter" :closable="false" :footer="null">
        <p class="pbz-font title">Filter</p>
        <a-row>
          <a-col :span="12">
            <a-date-picker size="large" />
          </a-col>
          <a-col :span="12">
            <a-select placeholder="Status" style="width: 100%" size="large">
              <a-select-option value="Selesai">
                Selesai
              </a-select-option>
              <a-select-option value="Gagal">
                Gagal
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="12" style="margin-top: 10px;">
          <div
            type="button"
            class="cancel-button pbz-font button-sm-medium"
            style="justify-content: left;"
          >
            Bersihkan Filter
          </div>
          </a-col>
          <a-col :span="12" style="display: flex; justify-content: flex-end; margin-top: 10px;">
          <div
            type="button"
            class="cancel-button pbz-font button-sm-medium"
            style="right: 0"
            @click="cancelModal"
          >
            Cancel
          </div>
          <div
            type="button"
            class="pbz-font accept-button mx-2"
            >Terapkan
          </div>

          </a-col>
        </a-row>
      </a-modal>
      <!-- end MODAL FILTER -->

    </a-row>
    <a-row >
        <div class="item-export" >
            <a-row class="d-flex align-items-center">
            <a-col :span="6">
                Tanggal
            </a-col>
            <a-col :span="5">
                User
            </a-col>
            <a-col :span="10">
                File
            </a-col>
            <a-col :span="3" class="d-flex justify-content-center">
                Status
            </a-col>
            </a-row>
        </div>
        <div class="pbz-font item-export" v-for="item in export_data" :key="item.id">
            <a-row class="d-flex justify-content-start align-items-center">
            <a-col :span="6" >
                27 Jan 2021 - 18.00
            </a-col>
            <a-col :span="4">
                {{item.user}}
            </a-col>
            <a-col :span="10">
                <a href="#">{{item.file}}</a>
            </a-col>
            <a-col :span="4" class="d-flex justify-content-end">
                <div v-if="item.status === 'Gagal'" type="button" class="status-export" style="backgound: #ffffff; color: #0559CB; border: 1px solid #0559CB; margin-right: 10px;">
                  Coba Lagi
                </div>
                <div class="status-export" :style="{background: getBackgroundColorStatus(item.status)}">
                  {{item.status}}
                </div>
            </a-col>
            </a-row>
        </div>

    <a-col :span="24" style="margin-top: 24px" class="">
      <Pagination
        :total="export_data.length"
        :page-size="export_data.length"
        :totalLengthPerPage="export_data.length"
        :defaultPage="1"
      />
    </a-col>
    </a-row>
  </div>
</template>

<script>
import FilterIcon from '@/components/Icons/Filter.vue'
import Pagination from '@/components/Pagination'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import { setBackgroundColorStatus } from '@/utils/fulfillment/index'

export default {
  name: 'fulfillment-export',
  components: {
    FilterIcon,
    ArrowIcon,
    Pagination,
  },
  data: function() {
    return {
      export_data: [
        {
          id: 1,
          user: 'zoro',
          file: '11_orders_export_20220105143333.xlsx',
          status: 'Selesai',
        },
        {
          id: 2,
          user: 'Luffy',
          file: '11_orders_export_20220105143333.xlsx',
          status: 'Gagal',
        },
        {
          id: 3,
          user: 'sanji',
          file: '11_orders_export_20220105143333.xlsx',
          status: 'Selesai',
        },
      ],
      filter: false,
    }
  },
  methods: {
    getBackgroundColorStatus (status) {
      return setBackgroundColorStatus(status)
    },
    handleFilter() {
      if (this.filter === true) {
        this.filter = false
      } else {
        this.filter = true
      }
    },
    cancelModal () {
      this.filter = false
    },
    back () {
      this.$router.push({
        path: '/fulfillment',
      })
    },
  },
}
</script>

<style lang="scss">
  .filter-box {
    width: 93px;
    height: 48px;
    padding: 2px;
    background: #0559CB;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    div {
      margin: 0 5px;
    }

    .caption {
      font-family: 'Poppins', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }
  }
.title {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  }
.item-export {
  margin-bottom: 1.5rem;
  position: static;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  padding: 10px 10px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;

  .status-export {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: fit-content;
    box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
    border-radius: 3px;
    color: #ffffff;
  }

  .accept-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    background: #0559cb;
    border-radius: 3px;
    color: #ffffff;
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 481px;
  height: 335px;
  border-radius: 15px;

  .modal-title {
    color: #1a1a1a;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-top: 40px;
  }
  .modal-text {
    color: #999999;
    margin-top: 10px;
    margin-bottom: 50px;
    padding: 5px 40px;
    font-size: 20px;
    line-height: 24px;
  }
  .modal-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    width: 401px;
    height: 48px;
    position: static;
    margin: 0px 35px;
  }
}

  .accept-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    background: #0559cb;
    border-radius: 3px;
    color: #ffffff;
  }

  .cancel-button {
    margin: 10px;
    line-height: 21px;
    color: #0559cb;
  }

</style>
