<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
    <path d="M1.00136 1.47109C3.00106 -0.490362 16.9989 -0.490362 18.9986 1.47109C19.3568 1.82239 19.6508 2.55121 19.8807 3.52231C20.7674 7.26845 16.5128 9.04931 13.7404 11.7687C12.9413 12.5525 12.4587 16.4052 12.2994 17.8944C12.2603 18.2601 12.0316 18.5795 11.6922 18.7355L9.15491 19.9016C8.48466 20.2096 7.70472 19.7597 7.64157 19.0364C7.43927 16.7189 6.97696 12.4723 6.25959 11.7687C3.48718 9.04931 -0.767362 7.26845 0.119343 3.52232C0.349204 2.55121 0.64321 1.82239 1.00136 1.47109Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'filter-icon',
}
</script>
